import {useSelector} from 'react-redux';
import {APIErrorInterface, ReduxState} from '../store/types';
import {useMemo} from 'react';
import {ServiceFeatureName} from '../store/types/ServiceFeature';
import {Subdivision} from '../store/types/Subdivision';
import {Country} from '../store/types/Country';
import {TimeZone} from '../store/types/TimeZone';
import {ConfigData} from '../store/types/ConfigData';
import {getServiceFeatureValue} from '../utils/extensions/getServiceValue';
import {sipTrunkFormDefaultValues, SipTrunkFormType} from "../components/Forms/SipTrunks/SipTrunk/SipTrunkForm.utils";

export const useSipTrunkFormTab = () => {
    const sipTrunk = useSelector(
        (state: ReduxState) => state.sipTrunks.sipTrunkDetails?.sipTrunk,
    );

    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.sipTrunks.sipTrunkDetails?.apiErrors,
    );

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>((state) => [
        {
            i_country_subdivision: 0,
            iso_3166_1_a2: '',
            iso_3166_2: '',
            name: '',
        },
        ...state.generic?.subdivisionsList,
    ]);

    const countriesList = useSelector<ReduxState, Country[]>(
        (state) => state.generic.countriesList,
    );

    const timeZonesList = useSelector<ReduxState, TimeZone[]>(
        (state) => state.generic.timeZonesList,
    );

    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );

    const {
        customerInfoDetails,
        serviceFeatures,
    } = useSelector((state: ReduxState) => state.extensions);

    const initialValues: SipTrunkFormType = useMemo(() => {
        if (!sipTrunk) return sipTrunkFormDefaultValues;

        const recalculateCliFeautureFlag = () => {
            const cliFutureEnabled = getServiceFeatureValue(
                ServiceFeatureName.Cli,
                serviceFeatures
            ) !== 'N';

            const cliFeautureValue = getServiceFeatureValue(
                ServiceFeatureName.Cli,
                serviceFeatures,
                'centrex'
            );

            return cliFutureEnabled
                ? cliFeautureValue
                : '';
        };

        return {
            sipTrunk: sipTrunk?.id || '',
            didNumber: sipTrunk?.numbers || [],
            displayNumber: recalculateCliFeautureFlag(),
            emailAddress: sipTrunk?.account_info?.email || '',
            timezone: sipTrunk?.account_info?.time_zone_name || '',
            country: sipTrunk?.account_info?.country || '',
            address: sipTrunk?.account_info?.baddr1 || '',
            city: sipTrunk?.account_info?.city || '',
            postalCode: sipTrunk?.account_info?.zip || '',
            state: sipTrunk?.account_info?.state || '',
            office:
                customerInfoDetails?.customer_info.i_office_type !== 1
                    ? customerInfoDetails?.customer_info.name || ''
                    : '',
            servicePassword: sipTrunk.account_info?.h323_password || '',
        };
    }, [
        sipTrunk,
        customerInfoDetails,
        serviceFeatures
    ]);

    return {
        initialValues,
        data: {
            didNumbers: customerInfoDetails?.didNumbers,
            accountId: sipTrunk?.account_info?.i_account,
            apiErrors: apiErrors,
            subdivisionsList: subdivisionsList,
            passwordConfig: config?.Web,
            passwordLength: config?.Accounts.CreditAccMinServicePasswdLength ? Number(config?.Accounts.CreditAccMinServicePasswdLength) : 6,
            countriesList: countriesList,
            timeZonesList: timeZonesList,
        },
    };
};
